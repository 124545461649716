import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NavigationService } from 'src/app/services/navigation.service';
import { CustomPageData } from 'src/schema';

@Component({
  selector: 'app-blog-item',
  templateUrl: './blog-item.component.html',
  styleUrls: ['./blog-item.component.scss'],
})
export class BlogItemComponent  implements OnInit {
  @Input() custom_page_data: CustomPageData = null;
  @Input() favorite_section: Boolean = false;

  constructor(
    public translateService: TranslateService,
    public navigationService: NavigationService
  ) { }

  ngOnInit() {  }

  go(){
    if (this.custom_page_data.youtube_only){
      window.open(this.custom_page_data.youtube_url, '_blank');
    }
    else{
      this.navigationService.navigate('root', 'article?short_name='+this.custom_page_data?.short_name);
    }
  }

}
