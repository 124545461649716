import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { CommonService } from './common.service';

const headers = { headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded') };

export interface Response {
  result: 'success' | 'fail' | 'error';
  data: any;
}

export enum ApiPath {
  get_system_data = 'get_system_data',
  upload_base64_file_to_server = 'upload_base64_file_to_server',
  get_all_rentable_car_data = 'get_all_rentable_car_data',
  user_login = 'user_login',
  request_phone_verify_code = 'request_phone_verify_code',
  new_user = 'new_user',
  update_user = 'update_user',
  get_user_data_by_id = 'get_user_data_by_id',
  get_all_data_by_data_type = 'get_all_data_by_data_type',
  get_single_data_by_data_type_and_id = 'get_single_data_by_data_type_and_id',
  get_all_car_data_exluding_disabled_by_model_group_by_color_code = 'get_all_car_data_exluding_disabled_by_model_group_by_color_code',
  get_available_campaign_by_user_id_and_car_id = 'get_available_campaign_by_user_id_and_car_id',
  get_unavailable_booking_date_list_by_car_id_and_user_id = 'get_unavailable_booking_date_list_by_car_id_and_user_id',
  calculate_car_rental_amount_by_booking_days_and_car_id_and_user_id = 'calculate_car_rental_amount_by_booking_days_and_car_id_and_user_id',
  get_temp_order_data_by_user_id = 'get_temp_order_data_by_user_id',
  update_temp_order = 'update_temp_order',
  get_return_data_and_pick_up_data_limit_by_car_id_and_start_date_and_end_date = 'get_return_data_and_pick_up_data_limit_by_car_id_and_start_date_and_end_date',
  get_rentable_parking_data_list_by_car_id = 'get_rentable_parking_data_list_by_car_id',
  get_available_equipment_by_booking_date_list_and_car_id_group_by_category = 'get_available_equipment_by_booking_date_list_and_car_id_group_by_category',
  get_available_product_data_list_by_car_id_and_booking_days = 'get_available_product_data_list_by_car_id_and_booking_days',
  generate_payment_data = 'generate_payment_data',
  apply_discount_to_payment = 'apply_discount_to_payment',
  get_awaiting_application_deposit_amount_and_create_date_by_user_id = 'get_awaiting_application_deposit_amount_and_create_date_by_user_id',
  get_all_usable_coupon_data_by_user_id_and_car_id = 'get_all_usable_coupon_data_by_user_id_and_car_id',
  get_all_coupon_data_by_user_id = 'get_all_coupon_data_by_user_id',
  make_order = 'make_order',
  pay = 'pay',
  pay_compensation_payment = 'pay_compensation_payment',
  calculate_payment_data_amount = 'calculate_payment_data_amount',
  calculate_compensation_payment_data_amount = 'calculate_compensation_payment_data_amount',
  get_order_data_with_car_data_with_payment_data_list_by_user_id = 'get_order_data_with_car_data_with_payment_data_list_by_user_id',
  get_next_payment_data_with_awaiting_payment_status_by_user_id = 'get_next_payment_data_with_awaiting_payment_status_by_user_id',
  make_extend_order = 'make_extend_order',
  get_bookable_maintenance_data = 'get_bookable_maintenance_data',
  get_appointment_data_before_n_days_by_order_id_and_maintenance_id = 'get_appointment_data_before_n_days_by_order_id_and_maintenance_id',
  make_appointment = 'make_appointment',
  get_current_appointment_data_by_order_id = 'get_current_appointment_data_by_order_id',
  get_unpaid_violation_data_list_by_user_id = 'get_unpaid_violation_data_list_by_user_id',
  user_submit_violation_payment_img = 'user_submit_violation_payment_img',
  request_payment_intent = 'request_payment_intent',
  cancel_payment_intent = 'cancel_payment_intent',
  get_customer_payment_method_by_user_id = 'get_customer_payment_method_by_user_id',
  detach_customer_payment_method_by_user_id_and_payment_method_id = 'detach_customer_payment_method_by_user_id_and_payment_method_id',
  scan_identity_card = 'scan_identity_card',
  get_available_car_by_car_viewing_date = 'get_available_car_by_car_viewing_date',
  new_car_viewing = 'new_car_viewing',
  update_car_viewing = 'update_car_viewing',
  get_latest_car_viewing_data_by_user_id = 'get_latest_car_viewing_data_by_user_id',
  get_unpaid_deposit_payment_data_by_user_id = 'get_unpaid_deposit_payment_data_by_user_id',
  get_unpaid_compensation_payment_data_list_by_user_id = 'get_unpaid_compensation_payment_data_list_by_user_id',
  get_past_order_data_list_by_user_id = 'get_past_order_data_list_by_user_id',
  get_rbb_data_by_user_id = 'get_rbb_data_by_user_id',
  get_all_available_plate_data = 'get_all_available_plate_data',
  get_all_rbb_equipment_excluding_disabled_by_id_list = 'get_all_rbb_equipment_excluding_disabled_by_id_list',
  calculate_rbb_payment_data_amount = 'calculate_rbb_payment_data_amount',
  pay_rbb = 'pay_rbb',
  update_rbb_handler = 'update_rbb_handler',
  get_rbb_payment_data_by_rbb_id = 'get_rbb_payment_data_by_rbb_id',
  get_most_recent_previous_order_drop_off_parking_data_by_car_id_list_and_date = 'get_most_recent_previous_order_drop_off_parking_data_by_car_id_list_and_date',
  new_emergency = 'new_emergency',
  send_towing_service_sms_by_emergency_id = 'send_towing_service_sms_by_emergency_id',
  get_seven_coupon_data_and_total_number_by_sorting_and_limit_or_search = 'get_seven_coupon_data_and_total_number_by_sorting_and_limit_or_search',
  update_seven_coupon = 'update_seven_coupon',
  get_notification_data_and_total_number_by_sorting_and_limit_by_user_id = 'get_notification_data_and_total_number_by_sorting_and_limit_by_user_id',
  add_user_id_to_hidden_user_id_list = 'add_user_id_to_hidden_user_id_list',
  apply_return_deposit_by_user_id = 'apply_return_deposit_by_user_id',
  get_all_faq_excluding_disabled = 'get_all_faq_excluding_disabled',
  get_vehicle_rental_agreement_by_order_id = 'get_vehicle_rental_agreement_by_order_id',
  get_return_vehicle_report_by_order_id = 'get_return_vehicle_report_by_order_id',
  new_vehicle_rental_agreement = 'new_vehicle_rental_agreement',
  update_vehicle_rental_agreement = 'update_vehicle_rental_agreement',
  update_return_vehicle_report = 'update_return_vehicle_report',
  generate_vehicle_rental_agreement_pdf_by_vra_data = 'generate_vehicle_rental_agreement_pdf_by_vra_data',
  get_all_active_custom_page = 'get_all_active_custom_page',
  get_custom_page_by_short_name = 'get_custom_page_by_short_name',
  delete_user_data_by_id = 'delete_user_data_by_id',
  get_all_rentable_parking_data_list = 'get_all_rentable_parking_data_list',
  get_car_data_by_id = 'get_car_data_by_id',
  get_all_awaiting_application_rbb_data = 'get_all_awaiting_application_rbb_data',
  get_all_active_prerent_car = 'get_all_active_prerent_car',
  get_available_public_factory_data_list = 'get_available_public_factory_data_list',
  get_all_parking_data_for_display_board = 'get_all_parking_data_for_display_board',
  get_bnb_vacancy_by_bnb_parking_id_list = 'get_bnb_vacancy_by_bnb_parking_id_list',
  get_carpark_vacancy_by_parking_id_list = 'get_carpark_vacancy_by_parking_id_list',

  create_prerent_order_checkout_session = 'create_prerent_order_checkout_session',
  pay_prerent_order_data_by_checkout_session = 'pay_prerent_order_data_by_checkout_session',

}

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(
    private http: HttpClient,
    private commonService: CommonService
  ) { }

  postFromServer(path: ApiPath, send_data: any | null, loadding?, loadingMsg?): Promise<any> {
    let body = new URLSearchParams();
    body.set(path, JSON.stringify(send_data));

    if (environment.enable_api_log){
      console.log(`${path}: `, send_data);
    }

    return new Promise((resolve, reject) => {
      if(loadding) this.commonService.presentLoading(null, loadingMsg);
      this.http.post(environment.api_url, body.toString(), headers).subscribe(async (res: any) => {
        // console.log(res);
        if(loadding) this.commonService.dismissLoading();
        if (res != null){
          const response: Response = res;
          switch (response.result) {
            case 'success':
              resolve(response);
              break;
            case 'fail':
              resolve(response);
              break;
            case 'error':
              this.errorHandler(path, response);
              reject(response)
              break;
  
            default:
              break;
          }
        }
        else{
          console.error(res);
          this.errorHandler(path);
          reject(null);
        }

      }),
        async (error: any) => {
          console.error(error);
          this.errorHandler(path, error);
          reject(error);
        };
    });
  }

  errorHandler(path: ApiPath, error?: any) {
    switch (path) {
      case ApiPath.get_system_data:

        break;

      default:
        let message = environment.production ? '出現錯誤' : `Api ${path} Error: ${JSON.stringify(error)}`;
        this.commonService.presentErrorToast(message);
        break;
    }
  }


}
