<ion-card class="blog-item-card" (click)="go()">
  <img *ngIf="custom_page_data?.banner_img_url_list.length > 0 && !favorite_section" [src]="('media_url' | env) + custom_page_data?.banner_img_url_list[0]" alt="">
  <ion-card-header>
    <ion-card-subtitle >
      <ion-label [style.color]="custom_page_data?.category_color">{{translateService.currentLang == 'zh' ? custom_page_data?.zh_category : custom_page_data?.en_category}}</ion-label>
      <ion-label>{{custom_page_data.release_date | date : 'yyyy-MM-dd'}}</ion-label>
    </ion-card-subtitle>
    <ion-card-title>
      {{translateService.currentLang == 'zh' ? custom_page_data?.zh_title : custom_page_data?.en_title}}
    </ion-card-title>
  </ion-card-header>
</ion-card>